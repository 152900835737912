import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Product from '../components/Product';
import Heading from '../components/Heading';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

import OffseasonLogo from "../assets/img/offszn25.jpeg";

function Products() {
    const [groupPrices, setGroupPrices] = useState([]);
    const [ptPrices, setPtPrices] = useState([]);

    useEffect(() => {
        async function fetchProducts(category) {
            const params = {
                action: 'products',
                category: category
            };
            const options = {
                method: 'GET',
            };
            const api = process.env.REACT_APP_SERVER_URL + '?' + (new URLSearchParams(params)).toString();
            const response = await fetch(api, options);
            return await response.json();
        }

        fetchProducts('pt').then(results => {
            setPtPrices(results);
        });
        fetchProducts('small-group').then(results => {
            setGroupPrices(results);
        });
    }, []);

    return (
        <>
            <Heading title={`Services`} />

            <div className={`bg-gray-100 py-6 lg:py-12`}>
                <div className={`container max-w-6xl mx-auto`}>
                    <h2 className="mb-6">Off-Season Training</h2>

                    <div className={`flex flex-col lg:flex-row`}>
                        <div className={`lg:w-1/2 lg:pr-2 order-2`}>
                            <p className={`mb-6`}>Pat's 15+ years experience is unmatched as he has coached not only some of the best local hockey players but also has worked with top NHL players across the country and alongside some of the best strength coaches in the industry</p>

                            <p className={`mb-6`}>The program over the years has continued to develop and has evolved beyond strength and conditioning and has transcended into sports science taking into account a holistic approach to performance.</p>

                            <p className={`mb-6`}>Spaces will remain limited in numbers to assure small group size with maximum hands-on coaching to tailor specific to each athlete's needs and goals. Each athlete has a different body and your training should reflect that.</p>
                        </div>
                        <div className={`lg:w-1/2 lg:pl-2 order-1 lg:order-3 mb-6 lg:mb-0`}>
                            <img src={OffseasonLogo} className={`w-full rounded-md`} alt={`Offseason24 Logo`} />
                        </div>
                    </div>

                    <p className={`mb-6 flex`}>
                        <Link to={`/offseason`} className={`btn btn--dark flex items-center`} role="link">Offszn25 Details <BsFillArrowRightCircleFill className={`text-xl ml-2`} /></Link>
                    </p>
                </div>
            </div>

            <div className={`bg-white py-12`}>
                <div className={`container max-w-6xl mx-auto`}>
                    <h2 className="mb-6">Personal Training</h2>

                    <p className={`mb-6`}>Personal training will always be the best and fastest way to achieve your goals. The sessions are fully customized 60-minute one-on-one training with Pat. 50 and 20-session packages include a program you can follow beyond the personal training sessions, at no additional cost. These packages also include assessing and monitoring body composition and performance tracking (testing).</p>

                    <div className="mt-12 grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {ptPrices.map((item, index) => (
                            <Product bg={`gray-100`} details={item} key={index} category={"pt"} showDescription={true} />
                        ))}
                    </div>
                </div>
            </div>

            <div className={`bg-gray-100 py-12`}>
                <div className={`container max-w-6xl mx-auto`}>
                    <h2 className="mb-6">Small Group Training</h2>

                    <p className={`mb-6`}>Busby Strength &amp; Conditioning was built to accommodate optimal small group training. Pat will coach 60-minute sessions using all the tools the gym has to offer. He prefers to keep the groups at a maximum of 5 athletes to allow for sufficient coaching and individual needs.</p>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {groupPrices.map((item, index) => (
                            <Product bg={`white`} details={item} key={index} plan={item.interval ? true : false} category={"small-group"} showDescription={true} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Products;
